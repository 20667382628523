<!--  -->
<template>
    <div class=''>
        <el-row>
            <el-col :span="12">
                <el-col :span="2">
                    <div class="blank"></div>
                </el-col>
                <el-button-group>
                    <el-button size="small" autofocus="true" @click="getWeekDay()">本周</el-button>
                    <el-button size="small" @click="getMonth()">本月</el-button>
                    <el-button size="small" @click="getYear()">本年</el-button>
                </el-button-group>

                <!-- <div class="mr20" v-for="(item, index) in bdTimeData" :key="index" @click="handleBdTime(index)"
                    :class="{ timeStyle: timeIndex == index }">{{ item.title }}
                            </div> -->

                <div align="left">
                    <div id="TendencySingoChart" :style="'width: 100%; height:300px'">
                    </div>
                </div>
            </el-col>
            <el-col :span="10">
                <div id="Bar" :style="'width:100%; height:300px'"></div>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="10">
                <div id="BabyChart" style="width: 100%;height:300px;"></div>
            </el-col>
            <el-col :span="8">
                <div id="myPie" style="width: 100%;height:300px;"></div>
            </el-col>
            <el-col :span="6">
                <div id="Gauge" style="width: 100%;height:300px;"></div>
            </el-col>
        </el-row>

    </div>
</template>
    
<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

export default {
    //import引入的组件需要注入到对象中才能使用
    components: {},
    data() {
        //这里存放数据
        return {
            // singo
            // loading: false,
            formerday: '',
            latterday: '',
            bdTimeIndex: "1",//默认显示本周数据
            bdScopeTime: "month",
            bdTimeData: [
                { title: "本周", value: "week" },
                { title: "本月", value: "month" },
                { title: "全年", value: "year" }
            ],
            option: {},
            Calendar_option: {},
            // 胎心仪
            time: '',
            heartRate: [],
            overRate: 0,
            lowRate: 0,
            normRate: 0,
            header: {},
            realName: '',
            gender: '',
            age: '',
            Babyoption: {},
            Pieoption: {},
            Gaugeoption: {},
            tem: 36.8,
            Baroption: {},
        };
    },
    //监听属性 类似于data概念
    computed: {},
    //监控data中的数据变化
    watch: {},
    //方法集合
    methods: {
        TimeProcess(time) {
            let y = time.getFullYear();
            let MM = time.getMonth() + 1;
            MM = MM < 10 ? ('0' + MM) : MM;
            let d = time.getDate();
            d = d < 10 ? ('0' + d) : d;
            return y + '-' + MM + '-' + d
        },
        getWeekDay() {
            var today = new Date();
            var formerday = new Date(today);
            formerday.setDate(today.getDate() - 6);
            this.latterday = this.TimeProcess(today)
            this.formerday = this.TimeProcess(formerday)
            console.log(this.formerday)
            console.log(this.latterday)
            this.title = '本周'
            this.initSingo()
            this.UpdateSingo_getConclusion(this.formerday, this.latterday)
        },
        getMonth() {
            // 本月指的是近30天的趋势
            var date = new Date()
            var MonthfirstDay = new Date(date);
            MonthfirstDay.setDate(date.getDate() - 30);
            this.latterday = this.TimeProcess(date)
            this.formerday = this.TimeProcess(MonthfirstDay)
            console.log(this.formerday)
            console.log(this.latterday)
            this.title = '本月'
            // this.getConclusion(this.formerday, this.latterday)
            this.initSingo()
            this.UpdateSingo_getConclusion(this.formerday, this.latterday)
        },
        getYear() {
            var date = new Date(), y = date.getFullYear();
            var YearfirstDay = new Date(y, 0);
            var YearlastDay = new Date(y + 1, 0, 0);
            this.latterday = this.TimeProcess(YearlastDay)
            this.formerday = this.TimeProcess(YearfirstDay)
            console.log(this.formerday)
            console.log(this.latterday)
            this.title = '本年'
            this.initSingo()
            this.UpdateSingo_getConclusion(this.formerday, this.latterday)
        },
        initSingo() {
            this.TendencySingoChart = this.$echarts.init(document.getElementById("TendencySingoChart"));
            this.TendencySingoChart.setOption(this.option);
            this.TendencySingoChart.showLoading({
                text: '请稍等, 正在为您加载数据',
                zlevel: 0,
                // 字体大小。
                fontSize: 20,
                // 是否显示旋转动画（spinner）。
                showSpinner: true,
                // 旋转动画（spinner）的半径。
                spinnerRadius: 16,
                // 旋转动画（spinner）的线宽。
                lineWidth: 8,
            });
        },
        UpdateSingo_getConclusion(former, latter) {
            // 根据uid、起止日期、返回所有静态心电结论
            this.$http({
                url: this.$http.adornUrl("/personal/staticecg/getConclusionByTime"),
                method: "post",
                data: this.$http.adornData({
                    uid: this.$store.state.women_baby.uid, startTime: former, endTime: latter
                }),
            }).then(({ data }) => {
                if (data && data.code === 0) {
                    console.log("看看conclusion");
                    console.log(data);
                    this.conclusionData = data.conclusions
                    if (this.conclusionData[this.conclusionData.length - 1] == ',') {
                        this.conclusionData = "[" + this.conclusionData.substring(0, this.conclusionData.length - 1) + "]";
                    }//JSON格式转换

                    this.xinlv = new Array(this.conclusionData.length)
                    this.xdgs = new Array(this.conclusionData.length)
                    this.xdgh = new Array(this.conclusionData.length)
                    this.xlbq = new Array(this.conclusionData.length)
                    this.dxtb = new Array(this.conclusionData.length)
                    this.fxzb = new Array(this.conclusionData.length)
                    this.jjxzb = new Array(this.conclusionData.length)
                    this.sxzb = new Array(this.conclusionData.length)
                    this.ssxxdgs = new Array(this.conclusionData.length)
                    this.sxxdgs = new Array(this.conclusionData.length)
                    this.fxyb = new Array(this.conclusionData.length)
                    this.sxyb = new Array(this.conclusionData.length)
                    this.fc = new Array(this.conclusionData.length)
                    this.timelist = new Array(this.conclusionData.length)
                    this.conclusion = new Array(this.conclusionData.length)
                    for (let i = 0; i < this.conclusion.length; i++) {
                        this.conclusion[this.conclusionData.length - 1 - i] = JSON.parse(this.conclusionData[i])
                    }
                    for (let i = 0; i < this.conclusion.length; i++) {
                        this.timelist[i] = this.conclusion[i].date
                        this.xinlv[i] = this.conclusion[i].xinlv
                        this.xdgs[i] = this.conclusion[i].xdgs
                        this.xdgh[i] = this.conclusion[i].xdgh
                        this.xlbq[i] = this.conclusion[i].xlbq
                        this.dxtb[i] = this.conclusion[i].dxtb
                        this.fxzb[i] = this.conclusion[i].fxzb
                        this.jjxzb[i] = this.conclusion[i].jjxzb
                        this.sxzb[i] = this.conclusion[i].sxzb
                        this.ssxxdgs[i] = this.conclusion[i].ssxxdgs
                        this.sxxdgs[i] = this.conclusion[i].sxxdgs
                        this.fxyb[i] = this.conclusion[i].fxyb
                        this.sxyb[i] = this.conclusion[i].sxyb
                        this.fc[i] = this.conclusion[i].fc
                    }

                    // 更新echarts数据
                    // 先清空之前的
                    this.option.title.text = [];
                    this.option.xAxis[0].data = [];
                    this.option.series[0].data = [];
                    this.option.series[1].data = [];
                    this.option.series[2].data = [];
                    this.option.series[3].data = [];
                    this.option.series[4].data = [];
                    this.option.series[5].data = [];
                    this.option.series[6].data = [];
                    this.option.series[7].data = [];
                    this.option.series[8].data = [];
                    this.option.series[9].data = [];
                    this.option.series[10].data = [];
                    this.option.series[11].data = [];
                    this.option.series[12].data = [];

                    // 再设置新的
                    if (this.conclusionData.length != 0) {
                        this.option.title.text.push(this.title + '趋势图')
                        this.option.title.y = 'top'
                        this.option.xAxis[0].data = this.timelist.map(function (str) {
                            return str.replace(' ', '\n');
                        })
                        this.option.series[0].data = this.xinlv;
                        this.option.series[1].data = this.xdgs;
                        this.option.series[2].data = this.xdgh;
                        this.option.series[3].data = this.xlbq;
                        this.option.series[4].data = this.dxtb;
                        this.option.series[5].data = this.fxzb;
                        this.option.series[6].data = this.jjxzb;
                        this.option.series[7].data = this.sxzb;
                        this.option.series[8].data = this.ssxxdgs;
                        this.option.series[9].data = this.sxxdgs;
                        this.option.series[10].data = this.jjxzb;
                        this.option.series[11].data = this.sxyb;
                        this.option.series[12].data = this.fc;
                    }
                    else {
                        this.option.title.text.push('暂无数据')
                        this.option.title.x = 'center'
                        this.option.title.y = 'center'
                        // this.option = {
                        //     title: {
                        //         text: '暂无数据',
                        //         x: 'center',
                        //         y: 'center',
                        //         textStyle: {
                        //             fontSize: 14,
                        //             fontWeight: 'normal',
                        //         }
                        //     }
                        // }
                    }
                    this.TendencySingoChart.hideLoading();
                    this.TendencySingoChart.setOption(this.option);

                } else {
                    // this.getCaptcha()
                    this.$message.error(data.msg);
                }
            });

        },
        initBabyChart(domName) {
            this.BabyChart = this.$echarts.init(domName);
            // 基于刚刚准备好的 DOM 容器，初始化 EChart 实例
        },
        Babychart() {
            this.Babyoption = {
                xAxis: {
                    type: 'value',
                    name: '时间(s)'
                },
                yAxis: {
                    type: 'value',
                    name: '心率(次/min)'
                },
                dataset: [
                    {
                        source: [
                            this.x,
                            this.heartRate
                        ]
                    },
                ],
                series: [
                    {
                        datasetIndex: 0,
                        symbol: "none" /*去掉小圆点*/,
                        // name: '心率',
                        type: "line",
                        seriesLayoutBy: "row",
                        markArea: {
                            silent: true,
                            itemStyle: {
                                opacity: 0.3
                            },
                            data: [
                                [
                                    {
                                        yAxis: 110
                                    },
                                    {
                                        yAxis: 160
                                    }
                                ],

                            ]
                        },
                    },
                ],
                legend: {

                }
            };
            this.BabyChart.setOption(this.Babyoption);
        },
        initPieChart(domName) {
            this.myPie = this.$echarts.init(domName);
            // 基于刚刚准备好的 DOM 容器，初始化 EChart 实例
        },
        BabyPie() {
            this.Pieoption = {
                tooltip: {
                    trigger: 'item',
                    formatter: '{a} <br/>{b} : {c} ({d}%)'
                },
                label: {
                    show: false,
                    position: 'center'
                },
                emphasis: {
                    label: {
                        show: true,
                        fontSize: 20,
                        fontWeight: 'bold'
                    }
                },
                labelLine: {
                    show: false
                },
                // toolbox: {
                //     show: true,
                //     feature: {
                //         mark: { show: true },
                //         dataView: { show: true, readOnly: false },
                //         restore: { show: true },
                //         saveAsImage: { show: true }
                //     }
                // },
                series: [
                    {
                        name: '胎心诊断结论',
                        type: 'pie',
                        radius: ['20%', '60%'],
                        center: ['50%', '60%'],
                        avoidLabelOverlap: false,
                        itemStyle: {
                            normal: {
                                color: function (colors) {
                                    var colorList = [
                                        '#F4D03F',
                                        '#E74C3C',
                                        '#91cd77',
                                    ];
                                    return colorList[colors.dataIndex];
                                },
                                borderRadius: 5,
                                borderColor: '#fff',
                                borderWidth: 2
                            },

                        },
                        data: [
                            { value: this.overRate, name: '心率过速' },
                            { value: this.lowRate, name: '心率过缓' },
                            { value: this.normRate, name: '心率正常' },
                        ]
                    }
                ]
            };
            this.myPie.setOption(this.Pieoption);
        },
        GaugeChart() {
            this.GaugeChart = this.$echarts.init(document.getElementById("Gauge"));
            this.Gaugeoption = {
                series: [
                    {
                        type: 'gauge',
                        center: ['50%', '60%'],
                        startAngle: 200,
                        endAngle: -20,
                        min: 0,
                        max: 60,
                        splitNumber: 12,
                        itemStyle: {
                            color: '#FFAB91'
                        },
                        progress: {
                            show: true,
                            width: 30
                        },
                        pointer: {
                            show: false
                        },
                        axisLine: {
                            lineStyle: {
                                width: 30
                            }
                        },
                        axisTick: {
                            distance: -45,
                            splitNumber: 5,
                            lineStyle: {
                                width: 2,
                                color: '#999'
                            }
                        },
                        splitLine: {
                            distance: -52,
                            length: 14,
                            lineStyle: {
                                width: 3,
                                color: '#999'
                            }
                        },
                        axisLabel: {
                            distance: -5,
                            color: '#999',
                            fontSize: 12
                        },
                        anchor: {
                            show: false
                        },
                        title: {
                            show: false
                        },
                        detail: {
                            valueAnimation: true,
                            width: '60%',
                            lineHeight: 40,
                            borderRadius: 8,
                            offsetCenter: [0, '-15%'],
                            fontSize: 40,
                            fontWeight: 'bolder',
                            formatter: '{value} °C',
                            color: 'inherit'
                        },
                        data: [
                            {
                                value: this.tem
                            }
                        ]
                    },
                    {
                        type: 'gauge',
                        center: ['50%', '60%'],
                        startAngle: 200,
                        endAngle: -20,
                        min: 0,
                        max: 60,
                        itemStyle: {
                            color: '#FD7347'
                        },
                        progress: {
                            show: true,
                            width: 8
                        },
                        pointer: {
                            show: false
                        },
                        axisLine: {
                            show: false
                        },
                        axisTick: {
                            show: false
                        },
                        splitLine: {
                            show: false
                        },
                        axisLabel: {
                            show: false
                        },
                        detail: {
                            show: false
                        },
                        data: [
                            {
                                value: this.tem
                            }
                        ]
                    }
                ]
            };
            this.GaugeChart.setOption(this.Gaugeoption);
        },
        BarChart() {
            this.BarChart = this.$echarts.init(document.getElementById("Bar"));
            this.Baroption = {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                xAxis: [
                    {
                        type: 'category',
                        data: ['血氧', '尿液', '血糖', '血脂', '三合一', '血压'],
                        axisTick: {
                            alignWithLabel: true
                        }
                    }
                ],
                yAxis: [
                    {
                        type: 'value',
                        name:'异常项计数'
                    }
                ],
                series: [
                    {
                        name: 'Direct',
                        type: 'bar',
                        barWidth: '60%',
                        data: [10, 52, 200, 334, 390, 330]
                    }
                ]
            };
            this.BarChart.setOption(this.Baroption);
        }
    },
    //生命周期 - 创建完成（可以访问当前this实例）
    created() {
        var today = new Date()
        this.year = today.getFullYear()
        this.cellSize = [22, 20]
        this.option = {
            animation: false,
            title: {
                text: '',
                x: 'center',
                y: 'top',
                textStyle: {
                    fontSize: 15
                }
            },
            grid: {
                top: 70,
                bottom: 80,
                left: 45,
                right: 50
            },
            toolbox: {
                feature: {
                    restore: {},
                    saveAsImage: {},
                    magicType: {
                        type: ['line', 'bar', 'stack'],
                        seriesIndex: {
                            line: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
                            bar: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
                            stack: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
                        }

                    }
                }
            },
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow',
                    animation: false,
                    label: {
                        backgroundColor: '#505765'
                    }
                }
            },
            legend: {
                data: ['心率', '心动过速', '心动过缓', '心律不齐', '窦性停搏', '房性早搏', '交界性早搏', '室性早搏', '室上性心动过速', '室性心动过速', '交界性逸博', '室性逸博', '房颤'],
                left: 'center',
                width: '70%',
                top: 20
            },
            dataZoom: [
                {
                    show: true,
                    realtime: true,
                    start: 0,
                    end: 100
                },
                {
                    type: 'inside',
                    realtime: true,
                    // start: 65,
                    // end: 85
                }
            ],
            xAxis: [
                {
                    type: 'category',
                    boundaryGap: false,
                    axisLine: { onZero: false, },
                    // prettier-ignore
                    data: '',
                }
            ],
            yAxis: [
                {
                    name: '心率(次/分钟)',
                    type: 'value',
                },
                {
                    name: '异常项疑似度(%)',
                    alignTicks: true,
                    type: 'value',
                }
            ],
            series: [
                {
                    name: '心率',
                    type: 'line',
                    color: 'firebrick',
                    label: {
                        show: true
                    },
                    // areaStyle: {},
                    lineStyle: {
                        width: 3
                    },
                    symbol: 'triangle',
                    symbolSize: 10,
                    emphasis: {   //折线图的高亮状态。
                        focus: 'series'
                    },
                    markArea: {
                        silent: true,
                        itemStyle: {
                            opacity: 0.3
                        },
                        data: [
                            [
                                {
                                    yAxis: 100
                                },
                                {
                                    yAxis: 200
                                }
                            ],

                        ]
                    },
                    // prettier-ignore
                    data: []
                },
                {
                    name: '心动过速',
                    type: 'bar',
                    stack: 'total',
                    // color: '#66b7f3 ',
                    yAxisIndex: 1,
                    // areaStyle: {},
                    lineStyle: {
                        width: 1
                    },
                    emphasis: {
                        focus: 'series'
                    },
                    // prettier-ignore
                    data: [],
                    lineStyle: {
                        width: 2
                    },
                },
                {
                    name: '心动过缓',
                    type: 'bar',
                    stack: 'total',
                    // color: '#0c84c6',
                    yAxisIndex: 1,
                    // areaStyle: {},
                    lineStyle: {
                        width: 1
                    },
                    emphasis: {
                        focus: 'series'
                    },
                    data: [],
                    lineStyle: {
                        width: 2
                    },
                },
                {
                    name: '心律不齐',
                    type: 'bar',
                    stack: 'total',
                    // color: '#2455a4 ',
                    yAxisIndex: 1,
                    // areaStyle: {},
                    lineStyle: {
                        width: 1
                    },
                    emphasis: {
                        focus: 'series'
                    },
                    data: [],
                    lineStyle: {
                        width: 2
                    },
                },
                {
                    name: '窦性停搏',
                    type: 'bar',
                    stack: 'total',
                    yAxisIndex: 1,
                    // color: ' #D2B4DE  ',
                    // areaStyle: {},
                    lineStyle: {
                        width: 1
                    },
                    emphasis: {
                        focus: 'series'
                    },
                    data: [],
                    lineStyle: {
                        width: 2
                    },
                },
                {
                    name: '房性早搏',
                    type: 'bar',
                    stack: 'total',
                    yAxisIndex: 1,
                    // color: ' #76448A   ',
                    // areaStyle: {},
                    lineStyle: {
                        width: 1
                    },
                    emphasis: {
                        focus: 'series'
                    },
                    data: [],
                    lineStyle: {
                        width: 2
                    },
                },
                {
                    name: '交界性早搏',
                    type: 'bar',
                    stack: 'total',
                    // color: '#F5B7B1 ',
                    yAxisIndex: 1,
                    // areaStyle: {},
                    lineStyle: {
                        width: 1
                    },
                    emphasis: {
                        focus: 'series'
                    },
                    data: [],
                    lineStyle: {
                        width: 2
                    },
                },
                {
                    name: '室性早搏',
                    type: 'bar',
                    stack: 'total',
                    // color: '#CB4335   ',
                    yAxisIndex: 1,
                    // areaStyle: {},
                    lineStyle: {
                        width: 1
                    },
                    emphasis: {
                        focus: 'series'
                    },
                    data: [],
                    lineStyle: {
                        width: 2
                    },
                },
                {
                    name: '室上性心动过速',
                    type: 'bar',
                    stack: 'total',
                    // color: '#F1C40F ',
                    yAxisIndex: 1,
                    // areaStyle: {},
                    lineStyle: {
                        width: 1
                    },
                    emphasis: {
                        focus: 'series'
                    },
                    data: [],
                    lineStyle: {
                        width: 2
                    },
                },
                {
                    name: '室性心动过速',
                    type: 'bar',
                    stack: 'total',
                    // color: '#d45e7e',
                    yAxisIndex: 1,
                    // areaStyle: {},
                    lineStyle: {
                        width: 1
                    },
                    emphasis: {
                        focus: 'series'
                    },
                    data: [],
                    lineStyle: {
                        width: 2
                    },
                },
                {
                    name: '交界性逸博',
                    type: 'bar',
                    stack: 'total',
                    color: ' #fc5252',
                    yAxisIndex: 1,
                    // areaStyle: {},
                    lineStyle: {
                        width: 1
                    },
                    emphasis: {
                        focus: 'series'
                    },
                    data: [],
                    lineStyle: {
                        width: 2
                    },
                },
                {
                    name: '室性逸博',
                    type: 'bar',
                    stack: 'total',
                    color: '#F0B27A ',
                    yAxisIndex: 1,
                    // areaStyle: {},
                    lineStyle: {
                        width: 1
                    },
                    emphasis: {
                        focus: 'series'
                    },
                    data: [],
                    lineStyle: {
                        width: 2
                    },
                },
                {
                    name: '房颤',
                    type: 'bar',
                    stack: 'total',
                    yAxisIndex: 1,
                    color: '#A6ACAF ',
                    // areaStyle: {},
                    lineStyle: {
                        width: 1
                    },
                    emphasis: {
                        focus: 'series'
                    },
                    data: [],
                    lineStyle: {
                        width: 2
                    },
                },

            ]
        }
        // var conclusion = this.$route.query.hbvalue.split(',')
        var conclusion = [160, 150, 130, 100, 150, 120, 110, 130]  // 假数据
        this.heartRate = conclusion.filter((item) => {  // 过滤所有零值
            return String(item) != '0';
        })
        console.log(this.heartRate)
        this.x = [];
        for (let i = 0; i < this.heartRate.length; i++) {
            this.x.push(i);
            // if (this.heartRate[i] > 160) {
            //     this.overRate++
            // } else if (this.heartRate[i] < 110) {
            //     this.lowRate++;
            // } else {
            //     this.normRate++
            // }
        }
        this.overRate = 3 // 假数据
        this.lowRate = 1 //假数据
        this.normRate = 10 //假数据

    },
    //生命周期 - 挂载完成（可以访问DOM元素）
    mounted() {
        this.getWeekDay()
        this.initBabyChart(document.getElementById("BabyChart"));
        this.Babychart()
        this.initPieChart(document.getElementById("myPie"));
        this.BabyPie()
        this.GaugeChart()
        this.BarChart()
    },
    beforeCreate() { }, //生命周期 - 创建之前
    beforeMount() { }, //生命周期 - 挂载之前
    beforeUpdate() { }, //生命周期 - 更新之前
    updated() { }, //生命周期 - 更新之后
    beforeDestroy() { }, //生命周期 - 销毁之前
    destroyed() { }, //生命周期 - 销毁完成
    activated() { }, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang="scss" scoped>
.host-body {
    .title {
        position: relative;
        width: 6.25rem;
        text-align: center;
        background-size: cover;
        background-repeat: no-repeat;

        .title-text {
            font-size: 0.3rem;
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translate(-50%);
        }

        .title-bototm {
            position: absolute;
            bottom: -0.375rem;
            left: 50%;
            transform: translate(-50%);
        }
    }

    // 平行四边形
    .react-left {
        cursor: pointer;
        font-size: 0.225rem;
        width: 3.75rem;
        height: 0.625rem;
        line-height: 0.625rem;
        text-align: center;
        transform: skewX(-45deg);

        .react-after {
            position: absolute;
            right: -0.3125rem;
            top: 0;
            height: 0.625rem;
            width: 0.625rem;
            background-color: #0f1325;
            transform: skewX(45deg);
        }

        .text {
            display: inline-block;
            transform: skewX(45deg);
        }
    }

    .react-right {
        cursor: pointer;
        // font-size: 0.225rem;
        // width: 3.75rem;
        // height: 0.625rem;
        // line-height: 0.625rem;
        text-align: center;
        transform: skewX(45deg);

        .react-before {
            position: absolute;
            left: -0.3125rem;
            top: 0;
            height: 0.625rem;
            width: 0.625rem;
            background-color: #0f1325;
            transform: skewX(-45deg);
        }

        .text {
            display: inline-block;
            transform: skewX(-45deg);
        }
    }

    .body-box {
        margin-top: 0.2rem;
        display: flex;
        flex-direction: column;

        //下方区域的布局
        .content-box {
            display: grid;
            grid-template-columns: 2fr 3fr 5fr 3fr 2fr;
        }

        // 底部数据
        .bototm-box {
            margin-top: 0.125rem;
            display: grid;
            grid-template-columns: repeat(2, 50%);
        }
    }
}
</style>